<template>
  <el-dialog
    :title="form.id ? '修改' : '新增'"
    :visible.sync="visible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="visible"
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      label-width="120px"
    >
      <el-form-item
        label="部门名称"
        prop="deptName"
        :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.deptName"
          v-input-filter
          maxlength="100"
        />
      </el-form-item>
      <!-- <el-form-item
                label="负责人"
        >
          <el-select
                  v-model="form.principalId"
                  placeholder="请选择人员"
          >
            <el-option
                    v-for="(item,index) in userOptions"
                    :key="index"
                    :label="item.actualName || item.nickName"
                    :value="item.id"
            />
          </el-select>
        </el-form-item> -->
      <el-form-item label="部门电话">
        <el-input
          v-model="form.deptPhone"
          v-input-filter
          type="number"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveOrUpdateDepts } from '@/api/system/dept/dept'
import {
  error, success, verifyEmali, verifyPhone,
} from '@core/utils/utils'
import { queryUserOptions } from '@/api/system/system'

export default {
  name: 'DeptSave',
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        deptName: '',
        deptPhone: '',
        principalId: '',
        parentId: '',
        id: 0,
      },
      userOptions: [],
      rules: {
        responsibleEmail: [
          // { required: true, message: '邮箱不能为空', trigger: 'change' },
          {
            validator: verifyEmali,
            trigger: 'change',
          },
        ],
        responsibleOfficePhone: [
          // { required: true, message: '号码不能为空', trigger: 'change' },
          {
            validator: verifyPhone,
            trigger: 'change',
          },
        ],
        deptPhone: [
          // { required: true, message: '号码不能为空', trigger: 'change' },
          {
            validator: verifyPhone,
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        deptName: '',
        deptPhone: '',
        principalId: '',
        parentId: '',
        id: 0,
      }
      this.$refs.form.resetFields()
    },
    getEnterpriseUersSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.userOptions = res.data.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.principalId = this.form.principalId ? this.form.principalId : 0
          saveOrUpdateDepts(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.visible = false
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    dialogOpen() {
      // this.getEnterpriseUersSelect()
    },
  },
}
</script>

<style scoped>
</style>
