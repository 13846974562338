<template>
  <el-dialog
    title="新增"
    :visible.sync="visible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="visible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="80px"
    >
      <el-form-item
        label="人员"
        prop="userIdList"
        :rules="[ { required: true, message: '人员不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.userIdList"
          class="mr10"
          placeholder="请选择人员"
          multiple
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { AddDeptUser } from '@/api/system/dept/dept'
import { error, success } from '@core/utils/utils'
import { FilterUserOptions } from '@/api/system/system'

export default {
  name: 'DeptSave',
  data() {
    return {
      visible: false,
      loading: false,
      depts: [],
      users: [],
      form: {
        userIdList: [],
        deptId: '',
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        userIdList: [],
        deptId: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          AddDeptUser(this.form)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.$emit('handleFilter', this.form.deptId)
                this.visible = false
              } else {
                error(resData.msg)
              }
            })
        }
      })
    },
    dialogOpen() {
      this.queryUserListByDeptId()
    },
    queryUserListByDeptId() {
      FilterUserOptions(this.form.deptId, 0)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.users = resData.data
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
