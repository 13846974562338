<template>
  <div>
    <PageIntroduce
      :title="'部门管理'"
      :desc="'此处“部门”既包含涉及个人信息处理的业务部门，也包括因个人信息安全保护需要，成立的跨业务部门、跨公司或机构的专项组织。'"
    />
    <b-card>
      <b-row>
        <b-col
          xl="4"
          md="4"
        >
          <div class="flex-between mb10">
            <h4>部门列表</h4>
            <div class="flex-end">
              <el-button
                v-if="importPerm"
                size="small"
                type="primary"
                icon="el-icon-upload"
                @click="importDialogShow"
              >
                导入
              </el-button>
              <el-button
                v-if="exportPerm"
                size="small"
                type="primary"
                icon="el-icon-download"
                @click="exportDept"
              >
                导出
              </el-button>
              <el-button
                v-if="saveParentDeptPerm"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="saveDialogShow(1)"
              >
                新增一级部门
              </el-button>
            </div>
          </div>
          <el-table
            :id="'textLeftTable'"
            v-loading="loading"
            class="dept-table"
            :data="deptTreeData"
            row-key="id"
            border
            :row-class-name="rowCls"
            :highlight-current-row="true"
            :tree-props="{children: 'children'}"
            @row-click="rowClick"
          >
            <el-table-column
              prop="deptName"
              :show-overflow-tooltip="true"
              :class-name="'leftText'"
              label="部门名称"
            />
            <el-table-column
              label="操作"
              :resizable="false"
            >
              <template v-slot="{row}">
                <el-link
                  v-if="updateDeptPerm"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(2,row)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-if="saveChildDeptPerm"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(3,row)"
                >
                  <el-tooltip
                    content="添加子部门"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-circle-plus-outline" />
                  </el-tooltip>
                </el-link>
                <!--                <el-link-->
                <!--                  class="mr10"-->
                <!--                  type="primary"-->
                <!--                  @click="addUserDialogShow(row.id)"-->
                <!--                >-->
                <!--                  <el-tooltip-->
                <!--                    content="添加用户"-->
                <!--                    placement="top"-->
                <!--                    effect="light"-->
                <!--                  >-->
                <!--                    <i class="el-icon-s-custom" />-->
                <!--                  </el-tooltip>-->
                <!--                </el-link>-->
                <el-link
                  v-if="delDeptPerm"
                  class="mr10"
                  type="primary"
                  @click="del(row)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </b-col>
        <b-col
          xl="8"
          md="8"
        >
          <div class="flex-between mb10">
            <h4>用户列表</h4>
            <el-button
              v-if="saveUserPerm"
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="addUserDialogShow"
            >
              添加人员
            </el-button>
          </div>
          <el-table
            v-loading="listUserLoading"
            :max-height="pageH - 80"
            border
            :data="userList"
          >
            <el-table-column
              prop="actualName"
              label="姓名"
            >
              <template v-slot="{row}">
                <div class="flex-start">
                  <span>{{ row.actualName }}</span>
                  <i
                    v-if="row.isPrincipal"
                    class="iconfont icon-xiangmuguanlirenyuan"
                    style="color: rgb(255, 159, 67) !important;font-size: 22px;margin-left: 5px"
                  />
                </div>

              </template>

            </el-table-column>
            <el-table-column
              prop="account"
              label="邮箱"
            />
            <el-table-column
              prop="deptName"
              label="所属部门"
            />
            <el-table-column
              prop="roleName"
              label="角色"
            />
            <el-table-column
              prop="jobTitle"
              label="职位"
            />
            <el-table-column
              label="操作"
              width="80px"
            >
              <template v-slot="{row}">
                <el-link
                  v-if="assignPerm && !row.isPrincipal"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="assignDeptUser(row.userId)"
                >
                  <el-tooltip
                    content="指定为部门负责人"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-s-custom" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-if="delUserPerm"
                  :underline="false"
                  type="primary"
                  @click="delDeptUser(row)"
                >
                  <el-tooltip
                    content="移除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>

        </b-col>
      </b-row>
      <DeptSave
        ref="deptSave"
        @handleFilter="getList"
      />
      <DeptAddUser
        ref="deptAddUser"
        @handleFilter="queryUserByDept"
      />
      <ImportDept
        ref="importDept"
        @handleFilter="getList"
      />
    </b-card>
  </div>
</template>

<script>
import {
  ListDept, DelDept, DeleteCurrentDeptUser, QueryUserListByDeptId, AssignDeptPrincipal, ExportDept,
} from '@/api/system/dept/dept'

import DeptSave from '@/views/enterprise/dept/DeptSave.vue'
import DeptAddUser from '@/views/enterprise/dept/DeptAddUser.vue'
import {
  error, success, treeDataTranslate, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import ImportDept from '@/views/enterprise/dept/ImportDept.vue'

export default {
  components: {
    DeptSave,
    DeptAddUser,
    PageIntroduce,
    ImportDept,
  },

  data() {
    return {
      loading: false,
      listUserLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      deptTreeData: [],
      userList: [],
      activeDeptId: '',
      saveParentDeptPerm: false,
      saveChildDeptPerm: false,
      updateDeptPerm: false,
      saveUserPerm: false,
      delDeptPerm: false,
      delUserPerm: false,
      assignPerm: false,
      importPerm: false,
      exportPerm: false,
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.saveParentDeptPerm = verifyBtnPerm(btnPerms, 'organization-2')
      this.saveChildDeptPerm = verifyBtnPerm(btnPerms, 'organization-3')
      this.updateDeptPerm = verifyBtnPerm(btnPerms, 'organization-4')
      this.saveUserPerm = verifyBtnPerm(btnPerms, 'organization-5')
      this.delDeptPerm = verifyBtnPerm(btnPerms, 'organization-6')
      this.delUserPerm = verifyBtnPerm(btnPerms, 'organization-7')
      this.assignPerm = verifyBtnPerm(btnPerms, 'organization-8')
      this.importPerm = verifyBtnPerm(btnPerms, 'organization-9')
      this.exportPerm = verifyBtnPerm(btnPerms, 'organization-10')
    })
    this.getList()
  },
  methods: {
    exportDept() {
      ExportDept()
    },
    importDialogShow() {
      this.$refs.importDept.dialogVisible = true
    },
    // eslint-disable-next-line consistent-return
    rowCls(row) {
      if (row.row.id === this.activeDeptId) {
        return 'current-row-active'
      }
    },
    delDeptUser(row) {
      this.$confirm('是否确认移除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          DeleteCurrentDeptUser({
            deptId: this.activeDeptId,
            principalId: row.userId,
          })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.queryUserByDept(this.activeDeptId)
              } else {
                error(resData.msg)
              }
            })
        })
    },
    rowClick(row) {
      this.activeDeptId = row.id
      this.queryUserByDept(row.id)
    },
    assignDeptUser(userId) {
      AssignDeptPrincipal({
        deptId: this.activeDeptId,
        principalId: userId,
      })
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.queryUserByDept(this.activeDeptId)
          } else {
            error(resData.msg)
          }
        })
    },
    queryUserByDept(deptId) {
      this.listUserLoading = true
      QueryUserListByDeptId(deptId)
        .then(res => {
          this.listUserLoading = false
          const resData = res.data
          if (resData.code === 0) {
            this.userList = resData.data
          }
        })
    },
    del(row) {
      if (row.children !== undefined) {
        error('该部门存在子部门，无法删除')
      } else {
        this.$confirm('是否确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        })
          .then(() => {
            DelDept({ id: row.id })
              .then(res => {
                const resData = res.data
                if (resData.code === 0) {
                  success(resData.msg)
                  this.userList = []
                  this.handleFilter()
                } else {
                  error(resData.msg)
                }
              })
          })
      }
    },
    saveDialogShow(type, row) {
      this.$refs.deptSave.visible = true
      // 添加一级部门
      if (type === 1) {
        this.$refs.deptSave.form.deptName = ''
        this.$refs.deptSave.form.parentId = 0
        this.$refs.deptSave.form.id = 0
        this.$refs.deptSave.form.deptPhone = ''
      } else if (type === 2) { // 编辑部门
        this.$refs.deptSave.form.deptName = row.deptName
        this.$refs.deptSave.form.principalId = row.principalId ? Number(row.principalId) : ''
        this.$refs.deptSave.form.parentId = row.parentId
        this.$refs.deptSave.form.deptPhone = row.deptPhone
        this.$refs.deptSave.form.id = row.id
      } else if (type === 3) { // 添加子部门
        this.$refs.deptSave.form.deptName = ''
        this.$refs.deptSave.form.parentId = row.id
        this.$refs.deptSave.form.principalId = ''
        this.$refs.deptSave.form.id = 0
        this.$refs.deptSave.form.deptPhone = ''
      }
    },
    addUserDialogShow() {
      this.$refs.deptAddUser.visible = true
      this.$refs.deptAddUser.form.deptId = this.activeDeptId
    },
    getList() {
      this.loading = true
      ListDept()
        .then(res => {
          this.loading = false
          const depts = res.data.data
          if (depts.length > 0) {
            this.deptTreeData = treeDataTranslate(depts, 'id', 'parentId')
            if (!this.activeDeptId) {
              this.activeDeptId = depts[0].id
            }
            this.queryUserByDept(this.activeDeptId)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
<style lang="scss">
    .dept-table tbody tr {
        cursor: pointer;
        .el-table__expand-icon {
            position: absolute;
            right: 0;
        }
    }
</style>
